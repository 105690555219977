<template>
   <div class="col-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after" :class="[
            { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
            { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
            { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
            { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
         ]">
            <div class="img-responsive-wrapper" v-if="(articolo.image || articolo.video) && showImage">
               <div>
                  <router-link :to="{
            path: linkPath,
            props: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: articolo.tipoContenuto
            },
         }">
                     <template v-if="articolo.image && !articolo.video">
                        <ImageComponent :imageSrc="articolo.image" :inList="inList" :inSection="inSection" :inCard="true" />
                     </template>

                     <template v-if="!articolo.image && articolo.video">
                        <VideoComponent :videoSrc="articolo.video" :inList="inList" :inCard="true" />
                     </template>

                     <!-- figure>
                     <img :src="articolo.image.src+'&w=360&q=75'" :alt="articolo.image.didascalia" class="card-img" style="min-height:280px; min-width:360px;object-fit:cover;"
                        :class="[
                           {['listcard']: inList }
                        ]">
                  </figure-->
                  </router-link>
               </div>
            </div>

            <div class="card-body flex-column d-flex" v-if="inList">
               <span class="date-detail">{{ articolo.dataPubblicazione }}</span>
               <router-link :to="{
            path: linkPath,
            props: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: articolo.tipoContenuto
            },
            params: {
               slug: articolo.slug
            }
         }">
                  <div class="position-relative">
                     <h5 class="text-black card-title">{{ articolo.titolo }}</h5>
                  </div>
               </router-link>
               <p class="card-text">
                  <Markdown :source="articolo.breve" />
               </p>

               <div class="head-tags mt-auto mb-2" v-if="articolo.topic !== undefined">
                  <div class="align-items-center  mt-4 mt-md-0">
                     <Chip v-if="articolo.topic !== undefined" :tagTitle="articolo.topic" :isTag="true"></Chip>
                  </div>
               </div>
            </div>

            <div v-else>
               <router-link :to="{
            path: linkPath,
            props: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: articolo.tipoContenuto
            },
            params: {
               slug: articolo.slug
            }
         }" exact>
                  <!-- div class="position-relative"-->
                  <div>
                     <div class="figcaption">{{ articolo.titolo }}</div>
                  </div>
               </router-link>
            </div>

         </div>
      </div>
   </div>
</template>
<script>
import Chip from '@/components/Chip';
import Markdown from 'vue3-markdown-it';
import ImageComponent from '@/components/ImageComponent';
import VideoComponent from '@/components/VideoComponent';


export default {
   components: {
      Chip,
      Markdown,
      ImageComponent,
      VideoComponent
   },

   props: {
      tipologia: {
         type: String,
         description: "Card type"
      },
      articolo: {
         type: Object,
         description: "Card object"
      },
      sezione: {
         type: String,
         description: "Card object"
      },
      showImage: {
         type: Boolean,
         default: true,
         description: "Mostra Immagine",
      },
      inList: {
         type: Boolean
      },
      inSection: {
         type: Boolean
      }
   },
   computed: {
      linkPath() {
         let schema = this.$props.articolo.schema !== undefined ? this.$props.articolo.schema : this.$props.articolo.schemaName;
         let currentPath = this.$route.fullPath.endsWith('/') ? this.$route.fullPath : this.$route.fullPath + '/'
         currentPath = currentPath.indexOf('?') > -1 ? this.$route.path + '/' : currentPath;
         if (schema == 'notizia' || schema == 'invito' || schema == 'evento') { currentPath = '/novita/' }

         return currentPath + schema + '/' + this.$props.articolo.slug
      },

      //         linkPath(){
      //            let ArticleSezione = this.$props.sezione == 'articolo' ? '/articolo' : '';
      //            return this.$route.fullPath +ArticleSezione+'/'+this.$props.articolo.slug;
      //         }
   }
};
</script>
