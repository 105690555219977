<template>
  <div class="col-12 col-md-12 col-lg-6 col-xl-4">

    <div class="card-wrapper card-space">
      <div class="card card-flex card-bg no-after" :class="[
        { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
        { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
        { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
        { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
      ]">

        <div class="img-responsive-wrapper" v-if="(articolo.image !== null || articolo.video !== null) && showImage">
          <div class="img-responsive">
            <router-link :to="{
        path: linkPath,
        props: {
          id: articolo.id,
          slug: articolo.slug,
          tipo: articolo.schemaName,
          tipoContenuto: articolo.tipoContenuto
        },
        params: {
          slug: articolo.slug
        }
      }">
              <template v-if="articolo.image && !articolo.video">
                <ImageComponent :imageSrc="articolo.image" :inList="inList" :inCard="true" />
              </template>

              <template v-if="!articolo.image && articolo.video">
                <VideoComponent :videoSrc="articolo.video" :inList="inList" :inCard="true" />
              </template>
            </router-link>
          </div>
        </div>

        <!-- CARD NORMALE IN LISTA -->
        <div class="card-body flex-column d-flex">
          <span class="date-detail">
            <svg class="icon icon-sm icon-primary mr-1">
              <use xlink:href="@/assets/img/sprite.svg#it-calendar"></use>
            </svg>
            {{ this.$filters.getFullDate(articolo.calendarioEventi[0].dataDal) }}
          </span>

          <span class="date-detail">
            <template v-if="articolo.calendarioEventi[0].dataAl && articolo.calendarioEventi[0].dataAl !== '' && articolo.calendarioEventi[0].dataAl !== undefined">
              <svg class="icon icon-sm icon-primary mr-1">
                <use xlink:href="@/assets/img/sprite.svg#it-calendar"></use>
              </svg>
              <!-- {{ this.$filters.getFullDate(articolo.calendarioEventi[(articolo.calendarioEventi.length-1)].dataAl) }} -->
              {{ this.$filters.getFullDate(articolo.calendarioEventi[0].dataAl) }}
            </template>
            <template v-else>
              <span>&nbsp;</span>
            </template>
          </span>

          <div class="category-top text-uppercase my-3">
            <b class="lightgrey-bg-a1 px-2 py-1 bordered lightgrey-border-color-a2 ">
              EVENTO
            </b>
          </div>

          <router-link :to="{
        path: linkPath,
        props: {
          id: articolo.id,
          slug: articolo.slug,
          tipo: articolo.schemaName,
          tipoContenuto: articolo.tipoContenuto
        },
        params: {
          slug: articolo.slug
        }
      }">
            <div class="position-relative">
              <h5 class="text-black card-title">{{ articolo.titolo }}</h5>
            </div>
          </router-link>
          <p class="card-text">
            <Markdown :source="articolo.breve" />
          </p>

          <div class="head-tags mt-auto mb-2" v-if="articolo.topic !== undefined || articolo.programma !== null || articolo.progettiStrategici !== null">
            <div class="align-items-center  mt-4 mt-md-0">
              <Chip v-if="articolo.programmaLink !== null && articolo.programmaLink[0].contenutoLink !== '/undefined'" :tagTitle="articolo.programmaLink" :isProgram="true" />
              <Chip v-if="articolo.topic !== undefined" :tagTitle="articolo.topic" :isTag="true" />
              <ProgettiStrategici v-if="(articolo.progettiStrategici !== undefined && articolo.progettiStrategiciOriginal !== undefined) && (articolo.progettiStrategici.length > 0 && articolo.progettiStrategiciOriginal.length > 0)" :progetto="articolo.progettiStrategici" :progettoFallback="articolo.progettiStrategiciOriginal" :isTag="true" :inCard="inList" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chip from '@/components/Chip';
import ImageComponent from '@/components/ImageComponent';
import ProgettiStrategici from '@/components/ProgettiStrategici';
import VideoComponent from '@/components/VideoComponent';
import Markdown from 'vue3-markdown-it';

export default {
  components: {
    Chip,
    ImageComponent,
    VideoComponent,
    ProgettiStrategici,
    Markdown
  },

  props: {
    type: {
      type: String,
      description: "Card type"
    },
    articolo: {
      type: Object,
      description: "Card object"
    },
    showImage: {
      type: Boolean,
      default: true,
      description: "Mostra Immagine",
    },
    inList: {
      type: Boolean
    }
  },

  computed: {
    linkPath() {
      let schema = this.$props.articolo.schema !== undefined ? this.$props.articolo.schema : this.$props.articolo.schemaName;
      let currentPath = this.$route.fullPath.endsWith('/') ? this.$route.fullPath : this.$route.fullPath + '/'
      currentPath = currentPath.indexOf('?') > -1 ? this.$route.path + '/' : currentPath;
      return currentPath + schema + '/' + this.$props.articolo.slug
    },

    imageSrc() {
      if (this.$props.articolo.video !== null) {
        return this.$props.articolo.video.posterSrc
      }
      else {
        if (this.$props.articolo.image.src.indexOf('unsplash') > -1) {
          return this.$props.articolo.image.src + '&w=540&h=414&q=75&fit=crop'
        }
        else {
          return this.$props.articolo.image.src;
        }
      }
    }
  }
};
</script>
<style scoped></style>
