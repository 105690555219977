<template>
   <div class="col-12 col-md-12" :class="[
      { ['col-lg-6 col-xl-4']: !inList },
   ]">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after" :class="[
      { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
      { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
      { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
      { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
   ]">

            <div class="img-responsive-wrapper" v-if="articolo.image && !inList && showImage">
               <div>
                  <router-link :to="{
      path: linkPath,
      props: {
         id: articolo.id,
         slug: articolo.slug,
         tipo: articolo.schemaName,
         tipoContenuto: articolo.tipoContenuto
      }
   }">
                     <figure>
                        <img :src="imageSrc" :alt="articolo.image.didascalia" class="card-img" style="min-height:280px; min-width:360px;object-fit:cover;" :class="[
      { ['listcard']: inList }
   ]">
                     </figure>
                  </router-link>
               </div>
            </div>

            <div class="card-body flex-column d-flex py-3" v-if="inList">
               <router-link :to="{
      path: linkPath,
      props: {
         id: articolo.id,
         slug: articolo.slug,
         tipo: articolo.schemaName,
         tipoContenuto: articolo.tipoContenuto
      }
   }">
                  <div class="position-relative">
                     <h5 class="text-black card-title">{{ articolo.titolo }}</h5>
                  </div>
               </router-link>
               <div class="card-text">
                  <div class="mt-2" v-if="articolo.breve">
                     <Markdown :source="articolo.breve" />
                  </div>

                  <div class="d-flex row py-2">
                     <div class="col-12 col-md-3 small">
                        <b class="lightgrey-color-a2">Data apertura invito</b>
                        <div class="date-detail">
                           <svg class="icon icon-primary icon-sm mr-2">
                              <use xlink:href="@/assets/img/sprite.svg#it-calendar"></use>
                           </svg>
                           <span class="date-detail">{{ $filters.getMonthYearDate(articolo.calendarioEventi[0].dataDal) }}</span>
                        </div>
                     </div>
                     <div class="col-12 col-md-3 small">
                        <b class="lightgrey-color-a2">Data chiusura invito</b>
                        <div class="date-detail">
                           <svg class="icon icon-primary icon-sm mr-2">
                              <use xlink:href="@/assets/img/sprite.svg#it-calendar"></use>
                           </svg>
                           <span class="date-detail">{{ $filters.getMonthYearDate(articolo.calendarioEventi[0].dataAl) }}</span>
                        </div>
                     </div>
                  </div>

                  <div class="d-flex row py-2" v-show="articolo.programmaLink[0] || articolo.priorita[0]">
                     <div class="col-12 col-md-3 small" v-if="articolo.programmaLink[0]">
                        <div class="w-100 font-weight-semibold lightgrey-color-a2">Programma</div>
                        <Chip :isProgram="true" :tagTitle="tags"></Chip>
                     </div>

                     <div class="col-12 col-md-9 small" v-if="articolo.priorita[0]">
                        <div class="w-100 font-weight-semibold lightgrey-color-a2">Priorità</div>
                        <Chip :tagTitle="articolo.priorita[0].titolo" :url="chipUrl"></Chip>
                     </div>
                  </div>

                  <div class="d-flex flex-row small card_invito">
                     <ProgettiStrategici v-if="(articolo.progettiStrategici !== undefined && articolo.progettiStrategiciOriginal !== undefined) && (articolo.progettiStrategici.length > 0 && articolo.progettiStrategiciOriginal.length > 0)" :progetto="articolo.progettiStrategici" :progettoFallback="articolo.progettiStrategiciOriginal" :isTag="true" :inCard="inList" />
                  </div>
                  <div class="d-flex flex-row small ">
                     <ServizioResponsabile v-if="articolo.strutturaOrganizzativa !== null" :servizio="articolo.strutturaOrganizzativa" :inCard="inList" />
                  </div>

                  <div class="head-tags mt-auto mt-2 mb-2" v-if="articolo.topic !== undefined">
                     <div class="d-flex flex-row small ">
                        <div class="align-items-center mt-4 mt-md-0 ">
                           <b class="sm text-secondary">Topic</b>
                           <div class="d-block">
                              <Chip v-if="articolo.topic !== undefined" :tagTitle="articolo.topic" :isTag="true"></Chip>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>


            <div v-else>
               <router-link :to="{
      path: linkPath,
      props: {
         id: articolo.id,
         slug: articolo.slug,
         tipo: articolo.schemaName,
         tipoContenuto: articolo.tipoContenuto
      }
   }" exact>
                  <div class="position-relative">
                     <div class="figcaption">{{ articolo.titolo }}</div>
                  </div>
               </router-link>
            </div>

         </div>
      </div>
   </div>
</template>
<script>
//   import shareArea from "@/components/ShareArea";
import Chip from '@/components/Chip';
import ProgettiStrategici from '@/components/ProgettiStrategici';
//import CampoIntervento from '@/components/CampoIntervento';
import ServizioResponsabile from '@/components/ServizioResponsabile';
import Markdown from 'vue3-markdown-it';

export default {
   components: {
      Chip,
      //CampoIntervento,
      ProgettiStrategici,
      ServizioResponsabile,
      Markdown
   },

   props: {
      type: {
         type: String,
         description: "Card type"
      },
      articolo: {
         type: Object,
         description: "Card object"
      },
      showImage: {
         type: Boolean,
         default: true,
         description: "Mostra Immagine",
      },
      sezione: {
         type: String,
         description: "Card object"
      },
      inList: {
         type: Boolean
      }
   },
   computed: {
      linkPath() {
         let schema = this.$props.articolo.schema !== undefined ? this.$props.articolo.schema : this.$props.articolo.schemaName;
         let currentPath = this.$route.fullPath.endsWith('/') ? this.$route.fullPath : this.$route.fullPath + '/'
         currentPath = currentPath.indexOf('?') > -1 ? this.$route.path + '/' : currentPath;
         return currentPath + schema + '/' + this.$props.articolo.slug
      },

      imageSrc() {
         if (this.$props.articolo.image.src.indexOf('unsplash') > -1) {
            return this.$props.articolo.image.src + '&w=540&h=414&q=75&fit=crop'
         } else {
            return this.$props.articolo.image.src;
         }
      },
      tags() {
         return Array.isArray(this.$props.articolo.programmaLink) ? this.$props.articolo.programmaLink[0] : this.$props.articolo.programma;
      },
      chipUrl() {
         if (this.$props.articolo.priorita[0].contenutoLink !== '') {
            return '/programmi/' + this.$props.articolo.priorita[0].contenutoLink + '/' + this.$props.articolo.priorita[0].schemaName + '/' + this.$props.articolo.priorita[0].slug;
         }
         else {
            return ""
         }
      }
      //         linkPath(){
      //            let ArticleSezione = this.$props.sezione == 'articolo' ? '/articolo' : '';
      //            return this.$route.fullPath +ArticleSezione+'/'+this.$props.articolo.slug;
      //         }
   }
};
</script>
<style>
.card_invito b.x-small.font-weight-semibold.lightgrey-color-a2 {
   font-size: 0.875rem !important;
}
</style>