<template>
   <div class="col-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after" :class="[
            { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
            { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
            { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
            { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
         ]">
            <router-link :to="{
            path: link,
            props: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: articolo.tipoContenuto
            }
         }">
               <div class="img-responsive-wrapper" v-if="articolo.image && showImage">
                  <template v-if="articolo.image && !articolo.video">
                     <ImageComponent :imageSrc="articolo.image" :inList="inList" :inSection="inSection" :inCard="true" />
                  </template>

                  <template v-if="!articolo.image && articolo.video">
                     <VideoComponent :videoSrc="articolo.video" :inList="inList" :inCard="true" />
                  </template>
               </div>

               <div class=" flex-column d-flex position-relative">
                  <h5 class="figcaption">{{ articolo.titolo }}</h5>
               </div>

            </router-link>
         </div>
      </div>
   </div>
</template>
<script>
//import shareArea from "@/components/ShareArea";
import ImageComponent from '@/components/ImageComponent';
import VideoComponent from '@/components/VideoComponent';
import { getFullLinkPath } from '@/service/'


export default {
   components: {
      //      shareArea,
      ImageComponent,
      VideoComponent,
   },

   data() {
      return {
         title: "",
         article: {},
         image: null
      }
   },
   props: {
      type: {
         type: String,
         description: "Card type"
      },
      sezione: {
         type: String,
         description: "Sezione",
         default: ''
      },
      articolo: {
         type: Object,
         description: "Card object"
      },
      showImage: {
         type: Boolean,
         default: true,
         description: "Mostra Immagine",
      },
      inList: {
         type: Boolean
      },
      inSection: {
         type: Boolean
      },
   },
   created() {
      this.article = this.$props.articolo;
      this.image = this.$props.articolo.image;
   },
   mounted() {
      this.article = this.$props.articolo;
      this.image = this.$props.articolo.image;
   },
   methods: {

   },
   computed: {
      link() {
         let preLink = this.$props.sezione !== '' ? this.$props.sezione + '/' : '';

         if (this.articolo.linkList !== undefined && this.articolo.linkList !== null) {
            if (this.articolo.linkList !== null && this.articolo.linkList.length > 0) {
               return preLink + this.articolo.linkList[0].linkURL;
            }
            else {
               return ((this.articolo.linkList !== null && this.articolo.linkList.length > 0) ? preLink + this.articolo.linkList[0].linkURL : preLink + this.articolo.slug)
            }
         } else {
            if (this.articolo.contenutoPadre !== null && this.articolo.contenutoPadre !== undefined) {
               return getFullLinkPath(this.articolo.contenutoPadre[0]) + '/' + this.$props.articolo.slug;
            }
            else {
               return preLink + this.articolo.slug
            }
         }
      }
   }
};
</script>
<style scoped></style>
