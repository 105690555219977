<template>
   <div class="col-12" :class="[
      {['col-sm-9 col-md-6 col-lg-9'] : !full }
      ]">
      <div class="row" ref="listPane">
         <template v-for="(item, index) in results" :key="index" >   
            <BaseCard :tipologia="item.schemaName" :isSearchCard="searchCard" :sezione="sezione" :item="item" :isInList="true"/>
         </template>
      </div>
   </div>
</template>

<script>
   //import Markdown from 'vue3-markdown-it';
   import BaseCard from '@/components/cards/BaseCard.vue';      

   export default {
      name: "ListaPane",
      components: {
      //   Markdown,
         BaseCard
      },
      data(){
         return { }
      },

      methods: {
      },     

      props: {
        results: [Array,Object],
        sezione: String,
        full: {
          type: Boolean,
          default: false
        },
        searchCard: {
          type: Boolean,
          default: false
        }
      },

      beforeMount() {
      },  

      mounted() {
      },  

      watch: {
      }      
   };
</script>
<style scoped>
</style>
