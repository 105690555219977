<template>
   <CardSimple v-if="simple === true" :articolo="item" :inList="isInList" :sezione="sezione" :tipologia="tipologia" :inSection="inSection" :showImage="showImage"></CardSimple>
   <CardRicerca v-else-if="isSearchCard == true" :inList="isInList" :articolo="item" :sezione="sezione" :tipologia="tipologia" :showImage="showImage"></CardRicerca>
   <CardArgomenti v-else-if="tipologia.toLowerCase() == 'argomenti'" :inList="isInList" :articolo="item" :sezione="sezione" :showImage="showImage"></CardArgomenti>
   <CardArticolo v-else-if="tipologia.toLowerCase() == 'articolo'" :inList="isInList" :articolo="item" :sezione="'articolo'" :inSection="inSection" :showImage="showImage"></CardArticolo>
   <CardContatto v-else-if="tipologia.toLowerCase() == 'contatto-rubrica'" :inList="isInList" :articolo="item" :sezione="sezione" :showImage="showImage"></CardContatto>
   <CardEvento v-else-if="tipologia.toLowerCase() == 'evento'" :inList="isInList" :articolo="item" :sezione="sezione" :showImage="showImage"></CardEvento>
   <CardImage v-else-if="tipologia.toLowerCase() == 'cardimage'" :inList="isInList" :articolo="item" :sezione="sezione" :showImage="showImage"></CardImage>
   <CardInvito v-else-if="tipologia.toLowerCase() == 'invito'" :inList="isInList" :articolo="item" :sezione="sezione" :tipologia="tipologia" :showImage="showImage"></CardInvito>
   <CardNotizia v-else-if="tipologia.toLowerCase() == 'notizia'" :inList="isInList" :articolo="item" :sezione="sezione" :showImage="showImage"></CardNotizia>
   <CardSezione v-else-if="tipologia.toLowerCase() == 'sezione'" :inList="isInList" :articolo="item" :sezione="sezione" :inSection="inSection" :showImage="showImage"></CardSezione>
   <CardProgramma v-else-if="tipologia.toLowerCase() == 'programma'" :inList="isInList" :articolo="item" :sezione="sezione" :inSection="inSection" :showImage="showImage"></CardProgramma>
   <CardPriorita v-else-if="tipologia.toLowerCase() == 'priorita'" :inList="isInList" :articolo="item" :sezione="sezione" :showImage="showImage"></CardPriorita>
   <CardList v-else-if="tipologia.toLowerCase() == 'blocco'" :inList="isInList" :articolo="item" :sezione="sezione" :tipologia="tipologia" :showImage="showImage"></CardList>
   <CardSimple v-else :articolo="item" :inList="isInList" :sezione="sezione" :tipologia="tipologia" :inSection="inSection" :showImage="showImage"></CardSimple>
</template>

<script>
import CardArgomenti from './CardArgomenti';
import CardArticolo from './CardArticolo';
import CardContatto from './CardContatto';
import CardEvento from './CardEvento';
import CardImage from './CardImage';
import CardInvito from './CardInvito';
import CardList from './CardList';
import CardNotizia from './CardNotizia';
import CardPriorita from './CardPriorita';
import CardProgramma from './CardProgramma';
import CardRicerca from './CardRicerca';
import CardSezione from './CardSezione';
import CardSimple from './CardSimple';

export default {
   name: 'BaseCard',
   components: {
      CardArgomenti,
      CardArticolo,
      CardContatto,
      CardEvento,
      CardImage,
      CardInvito,
      CardList,
      CardNotizia,
      CardPriorita,
      CardProgramma,
      CardRicerca,
      CardSezione,
      CardSimple,
   },
   props: {
      tipologia: {
         type: String,
         description: "Tipologia del card",
         required: true
      },
      sezione: {
         type: String,
         description: "Area",
         required: false
      },
      item: {
         type: Object,
         description: "Articolo",
         required: true
      },
      showImage: {
         type: Boolean,
         description: "Mostra immagine del card",
         default: true
      },
      isInList: {
         type: Boolean,
         default: false
      },
      inSection: {
         type: Boolean,
         default: false
      },
      isSearchCard: {
         type: Boolean,
         default: false
      },
      simple: {
         type: Boolean,
         default: false,
         description: "Simple card only",
      }
   }
}   
</script>