<template>
   <div class="col-12 col-sm-12 col-md-6 col-lg-3" ref="filterList" id="filterList">
      <div class="form-group mb-4" v-show="who !== 'invito'">
         <h6>DATA DI PUBBLICAZIONE</h6>
         <div class="it-datepicker-wrapper mt-3">
            <div class="input-group input-group-sm small">
               <div class="input-group-prepend">
                  <span class="input-group-text">
                     <svg class="icon icon-sm icon-primary">
                        <use xlink:href="@/assets/img/sprite.svg#it-calendar"></use>
                     </svg>
                  </span>
               </div>
               <VueDatePicker v-model="filters.period.dal" placeholder="DAL" :locale="locale" :inputFormat="'dd/MM/yyy'" :upperLimit="filters.period.al" :clearable="true" :typeable="true" />
            </div>
         </div>
         <div class="it-datepicker-wrapper mt-3">
            <div class="input-group input-group-sm small">
               <div class="input-group-prepend">
                  <span class="input-group-text">
                     <svg class="icon icon-sm icon-primary">
                        <use xlink:href="@/assets/img/sprite.svg#it-calendar"></use>
                     </svg>
                  </span>
               </div>
               <VueDatePicker v-model="filters.period.al" placeholder="AL" :locale="locale" :inputFormat="'dd/MM/yyy'" :lowerLimit="filters.period.dal" :clearable="true" :typeable="true" />
            </div>
         </div>
      </div>

      <template v-for="(fv, fk, fi) in filtri" :key="fi">
         <div class="form-group mb-4" v-if="fv.buckets.length > 0 && fk !== 'MONTHS' && fk !== 'SERVIZIO_RESPONSABILE' && fk !== 'TOPIC' && fk !== 'PROGETTI_STRATEGICI'" :id="`filtergroup-${fi}`">
            <h6 class="small font-weight-bold mb-0">{{ fk.replace(/_/g, " ") }}</h6>

            <div class="input-group input-group-sm mb-3" v-if="fk !== 'AREA_GEOGRAFICA' && fk !== 'PROGRAMMA'">
               <label :for="`field-cerca-${fi}`" class="sr-only active" style="transition: none 0s ease 0s;">Cerca</label>
               <div class="input-group-prepend">
                  <span class="input-group-text">
                     <svg class="icon icon-xs">
                        <use xlink:href="@/assets/img/sprite.svg#it-search"></use>
                     </svg>
                  </span>
               </div>
               <input type="search" :id="`field-cerca-${fi}`" placeholder="Cerca" class="form-control form-control-sm" @keyup="filterFacetList(fi, $event.target.value)" debounce="500" />
               <div class="input-group-append hidden" :id="`resetFacetSearch-${fi}`">
                  <button class="btn no-radius btn-minimal-padding" @click="clearField(fi)" name="resetRicerca">
                     <svg class="icon icon-sm">
                        <use xlink:href="@/assets/img/sprite.svg#it-close-big"></use>
                     </svg>
                  </button>
               </div>
            </div>

            <div :id="`facetlist-${fi}`">
               <div class="form-check mt-0">
                  <input :id="`tutti-filter-${fi}`" type="checkbox" @click="toggleCheckBoxes(`${fi}`)">
                  <label :for="`tutti-filter-${fi}`" class="mb-0">Tutti</label>
               </div>

               <template v-for="(item, index) in fv.buckets" :key="index">
                  <div class="form-check mt-0" :class="[
                     { ['hidden-filter hidden']: index > (limit_facet - 1) },
                  ]">
                     <input :id="`filter-${fi}-${index}`" type="checkbox" :value="`${fk}:${item.key}`" :title="item.key" :data-parent="fk" :data-parent-index="fi" v-model="filters.filtri" @change="changeClass($event)">
                     <label class="mb-0" :for="`filter-${fi}-${index}`">{{ item.key }}</label>
                  </div>
               </template>
               <div v-show="fv.buckets.length > limit_facet" class="text-left pl-4">
                  <button class="btn btn-outline-none btn-sm py-1 px-2 mt-2" :id="`filtergroupBtn-${fi}`" @click="toggleMenuList(this, fi)">
                     <svg class="icon icon-sm mr-1">
                        <use xlink:href="@/assets/img/sprite.svg#it-plus-circle"></use>
                     </svg>
                     <span>mostra altri</span>
                  </button>
               </div>
            </div>
         </div>
      </template>

      <template v-for="(fv, fk, fi) in filtri" :key="fk">
         <selectDropDown v-if="this.$props.filtri !== null && fv.buckets.length > 0 && (fk === 'SERVIZIO_RESPONSABILE' || fk === 'TOPIC' || fk == 'PROGETTI_STRATEGICI')" :id="`filtergroup-${fi}`" :title="`${fk}`" :items="filtri[`${fk}`]" ref="`${fk}`" :filterpreselect="getPreselectedDropDownItems(fk)" @remove-select-filter="removeSelectFilter" @add-select-filter="addSelectFilter" @reset-to-all="selectAllSelectFilter" />
      </template>

      <button class="btn btn-outline-primary w-100 py-1 br-2" v-show="filterSelected.filtri.length > 0 || selected_responsabili.length > 0 || filters.filtri.length > 0 || (filters.period.dal !== null || filters.period.al !== null)" @click="unCheckAllCheckBoxes('filterList')">CANCELLARE FILTRI</button>
   </div>
</template>

<script>
import VueDatePicker from 'vue3-datepicker';
import { it } from 'date-fns/locale'
import selectDropDown from '@/components/SelectDropDown';

export default {
   name: "ListaFiltri",
   components: {
      VueDatePicker,
      selectDropDown
   },

   props: {
      article: Object,
      filtri: Object,
      who: String,
      filterSelected: Object,
   },

   data() {
      return {
         presearched: false,
         monthSelected: '*',
         programSelected: '',
         searchTerm: '',
         selected_responsabili: [],
         responsabiliTesto: '',
         limit_facet: 5,

         filters: {
            limit: 5,
            period: {
               dal: null,
               al: null
            },
            filtri: []
         },
         locale: it,
      }
   },

   methods: {
      changeClass(ele) {
         let totalChecks = document.querySelectorAll('input[type="checkbox"][id^="filter-' + ele.target.dataset.parentIndex + '"]').length;
         let totalChecksChecked = document.querySelectorAll('input[type="checkbox"][id^="filter-' + ele.target.dataset.parentIndex + '"]:checked').length;
         let totalAllCheck = document.querySelectorAll('input[type="checkbox"][id="tutti-filter-' + ele.target.dataset.parentIndex + '"]')[0];

         if (ele.target.checked) {
            ele.target.classList.add('checked');
         }
         else {
            ele.target.classList.remove('checked');
         }

         if (totalChecks == totalChecksChecked) {
            totalAllCheck.indeterminate = false;
            totalAllCheck.checked = true;
            totalAllCheck.classList.add('checked');
         }
         else if (totalChecksChecked > 0 && totalChecksChecked < totalChecks) {
            totalAllCheck.indeterminate = true;
            totalAllCheck.checked = false;
            totalAllCheck.classList.remove('checked');
            totalAllCheck.classList.add('semi-checked');
         }
         else {
            totalAllCheck.indeterminate = false;
            totalAllCheck.checked = false;
            totalAllCheck.classList.remove('semi-checked');
            totalAllCheck.classList.remove('checked');
         }
      },

      changeMonthSelected(event) {
         var date = new Date(parseFloat(event));
         var month = (date.getMonth() + 1).toString().padStart(2, '0');
         var year = date.getFullYear();
         var lastDate = (new Date(year, month, 0)).getDate();
         if (event === '*') {
            this.filters.period.dal = null
            this.filters.period.al = null;
         }
         else {
            this.filters.period.dal = new Date(year + '-' + month + '-01');
            this.filters.period.al = new Date(year + '-' + month + '-' + lastDate);
         }
      },

      changeProgrammaSelected(event) {
         if (this.filters.filtri.includes(event)) {
            this.filters.filtri = this.filters.filtri.filter(function (item) {
               return item !== event
            })
         } else {
            this.filters.filtri.push(event)
         }
      },

      clearField(who) {
         document.querySelector('#field-cerca-' + who).value = '';
         this.filterFacetList(who, '');
      },

      filterFacetList(who, term) {
         let text = term.toLowerCase();
         let resetBtn = document.querySelector('#resetFacetSearch-' + who);
         var checks = document.querySelectorAll('#facetlist-' + who + ' input[type="checkbox"]')

         if (text === '') {
            for (var j = 0; j < checks.length; j++) {
               let check = checks[j];
               check.parentNode.classList.remove('hidden');
            }
            resetBtn.classList.add('hidden');
         }
         else {
            for (var i = 0; i < checks.length; i++) {
               let check = checks[i];
               if (check.title.toLowerCase().indexOf(text) > -1) {
                  check.parentNode.classList.remove('hidden');
               }
               else {
                  check.parentNode.classList.add('hidden');
               }
            }
            resetBtn.classList.remove('hidden');
         }
      },

      addSelectFilter(res) {
         if (!this.filters.filtri.includes(res)) {
            this.filters.filtri.push(res)
            this.$emit('listenChangeFilter', this.filters);
         } else {
            this.$emit('listenChangeFilter', this.filters);
         }
      },

      getPreselectedDropDownItems(who) {
         let items = []
         for (let itemVal of this.filters.filtri) {
            if (itemVal.indexOf(who) > -1) {
               items.push(itemVal)
            }
         }

         return items;
      },

      removeSelectFilter(res) {
         this.filters.filtri = this.filters.filtri.filter(function (item) {
            return item !== res
         })
      },

      selectAllSelectFilter(res) {
         this.filters.filtri = this.filters.filtri.filter(function (item) {
            if (item.indexOf(res + ':') === -1) {
               return item
            }
         })
         this.$emit('listenChangeFilter', this.filters);
      },

      toggleFilterSelected(whichCheck, addOrRemove) {
         let who = whichCheck.value;

         if (addOrRemove) {
            //add
            if (!this.filters.filtri.includes(who)) {
               this.filters.filtri.push(who)
            }
         }
         else {
            //remove
            if (this.filters.filtri.includes(who)) {
               this.filters.filtri = this.filters.filtri.filter(function (item) {
                  return item !== who
               })
            }
         }
      },

      toggleCheckBoxes(divid) {
         var checkAllFlag = document.getElementById('tutti-filter-' + divid).checked;
         document.getElementById('tutti-filter-' + divid).indeterminate = true;
         var checks = document.querySelectorAll('#filtergroup-' + divid + ' input[type="checkbox"]');
         for (var i = 1; i < checks.length; i++) {
            var check = checks[i];
            check.checked = checkAllFlag;
            this.toggleFilterSelected(check, checkAllFlag);
         }
      },

      toggleMenuList(caller, who) {
         var menuListChecks = document.querySelectorAll('#filtergroup-' + who + ' div.hidden-filter');
         var menuListChecksHidden = document.querySelectorAll('#filtergroup-' + who + ' div.hidden-filter.hidden');
         var button = document.querySelector('#filtergroupBtn-' + who);

         if (menuListChecks.length == menuListChecksHidden.length) {
            for (var i = 0; i < menuListChecksHidden.length; i++) {
               menuListChecksHidden[i].classList.remove('hidden');
            }
            button.querySelector('span').innerHTML = 'mostra meno'
            button.querySelector('use').setAttribute('xlink:href', '/img/sprite.svg#it-minus-circle')
         } else {
            for (var j = 0; j < menuListChecks.length; j++) {
               menuListChecks[j].classList.add('hidden');
            }
            button.querySelector('span').innerHTML = 'mostra altri'
            button.querySelector('use').setAttribute('xlink:href', '/img/sprite.svg#it-plus-circle')
         }
      },

      unCheckAllCheckBoxes(divid) {
         var checks = document.querySelectorAll('#' + divid + ' input[type="checkbox"]');
         for (var i = 0; i < checks.length; i++) {
            var check = checks[i];
            check.classList.remove('semi-checked');
            if (!check.disabled) {
               check.checked = false;
            }
         }

         this.filters.period.dal = this.filters.period.al = null;
         this.filters.filtri = [];
         this.$emit('listenChangeFilter', this.filters);
      },
   },

   mounted() {
      if (this.$props.filterSelected) {
         this.filters = this.$props.filterSelected;
         this.presearched = true
      }
      if (this.$route.query.programma) {
         this.programSelected = 'PROGRAMMA:' + this.$route.query.programma
      }
   },

   computed: {
      months() {
         let monthOptions = [];

         if (this.$props.filtri !== null && this.$props.filtri.MONTHS !== undefined && this.$props.filtri.MONTHS.buckets.length > 0) {
            monthOptions.push({ value: '*', text: 'TUTTI' });

            for (let i in this.$props.filtri.MONTHS.buckets) {
               let val = this.$props.filtri.MONTHS.buckets[i].key_as_string.split(' - ');
               monthOptions.push({ value: this.$props.filtri.MONTHS.buckets[i].key, text: val[0] + ' - ' + this.$root.$filters.getFullMonthName(val[1]) });
            }
         }
         return monthOptions;
      }
   },

   updated() {
      this.presearched = false
   },

   watch: {
      filters: {
         handler(newValue) {
            if (!this.presearched) {
               this.$emit('listenChangeFilter', newValue);
            }
         },
         deep: true
      },
      monthSelected(newValue, oldValue) {
         if (this.filters.filtri.includes(oldValue)) {
            this.filters.filtri = this.filters.filtri.filter(function (item) {
               return item !== oldValue
            })
         }
         else {
            if (newValue !== '*' || newValue !== '') {
               this.filters.filtri.push(newValue)
            }
         }
      },
      programSelected(newValue, oldValue) {
         if (this.filters.filtri.includes(oldValue)) {
            this.filters.filtri = this.filters.filtri.filter(function (item) {
               return item !== oldValue
            })
         }
         else {
            if (newValue !== '*' || newValue !== '') {
               this.filters.filtri.push(newValue)
            }
         }
      },
   }
};
</script>

<style>
.form-group {
   margin-bottom: 2.5rem;
}

.form-check [type="checkbox"]+label,
.form-check [type="radio"]+label {
   font-size: 0.889rem;
   height: 28px;
   line-height: 28px;
   font-weight: 400;
}

.hidden {
   display: none !important; 
}

.form-check [type="checkbox"],
.form-check [type="radio"] {
   top: 4px;
   left: 5px;
   visibility: hidden;
}

.form-check [type="checkbox"]:checked+label::before {
   top: 4px;
}

.form-check [type="checkbox"]+label::after {
   margin: 4px 5px;
}

.dropdownTextSelect {
   position: absolute;
   z-index: 600;
   border: 1px solid #cdcdcd;
   max-height: 400px;
   top: 44px;
   width: 100%;
   overflow-y: scroll;
   box-shadow: 2px 2px 8px 2px rgba(80, 80, 80, 0.1);
   background: white;
}

.dropdownTextSelect .dropdown-item:hover {
   color: #FFF;
   background-color: #219EBC;
}

#filterList .form-group label {
   white-space: break-spaces;
   overflow: visible;
}

.btn-outline-primary {
   background-color: transparent;
   border: 2px solid #023047;
   box-shadow: none;
}

.btn-outline-primary:hover {
   background-color: #219EBC;
   border: 2px solid #219EBC;
}

.icon.invert {
   transform: rotate(180deg);
}

.form-check .semi-checked[type="checkbox"]+label,
.form-check [type="checkbox"]:checked+label {
   font-weight: 600;
}

.btn-group {
   border-bottom: 1px solid #5c6f82;
}

.v3dp__elements .selected {
   .background-color: "#219ebc";
}

.v3dp__elements {
   --vdp-bg-color: "#ffffff";
   --vdp-text-color: "#000000";
   --vdp-box-shadow: "0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)";
   --vdp-border-radius: "3px";
   --vdp-heading-size: "2.5em";
   --vdp-heading-weight: "bold";
   --vdp-heading-hover-color: "#eeeeee";
   --vdp-arrow-color: "currentColor";
   --vdp-elem-color: "currentColor";
   --vdp-disabled-color: "#d5d9e0";
   --vdp-hover-color: "#ffffff";
   --vdp-hover-bg-color: "#219ebc";
   --vdp-selected-color: "#ffffff";
   --vdp-selected-bg-color: "#219ebc";
   --vdp-current-date-outline-color: "#888888";
   --vdp-current-date-font-weight: "bold";
   --vdp-elem-font-size: "0.8em";
   --vdp-elem-border-radius: "3px";
   --vdp-divider-color: "#d5d9e0";
}

.v3dp__clearable {
   position: absolute;
   right: 0px;
   left: unset;
   position: absolute;
   top: 6px;
   right: 6px;
}

.v3dp__clearable i {
   text-transform: uppercase;
   font-style: normal;
   font-stretch: extra-expanded;
}
</style>
