<template>
   <div class="col-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after" :class="[
            { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
            { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
            { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
            { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
         ]">
            <div class="img-responsive-wrapper" v-if="articolo.image && showImage">
               <div>
                  <router-link :to="{
            name: getNameLink(tipologia),
            path: link,
            props: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: tipologia
            },
            params: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: tipologia
            }
         }">
                     <figure>
                        <img :src="imageSrc" :alt="articolo.image.didascalia" class="card-img" style="min-height:280px; min-width:360px;object-fit:cover;" :class="[
            { ['listcard']: inList }
         ]">
                     </figure>
                  </router-link>
               </div>
            </div>


            <div class="card-body flex-column d-flex">

               <div class="category-top text-uppercase my-3">
                  <b class="lightgrey-bg-a1 px-2 py-1 bordered lightgrey-border-color-a2 ">
                     {{ articolo.schemaName }}
                  </b>
               </div>

               <span class="date-detail">{{ datePub }}</span>

               <router-link :to="{
            name: getNameLink(tipologia),
            path: link,
            props: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: tipologia
            },
            params: {
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: tipologia
            }
         }">

                  <div class="position-relative">
                     <h5 class="text-black card-title">{{ articolo.titolo }}</h5>
                  </div>
               </router-link>
               <p class="card-text">
                  <Markdown :source="articolo.breve" />
               </p>
               <div class="head-tags mt-auto mb-2" v-if="articolo.topic !== undefined || articolo.programma !== undefined || articolo.progettiStrategici !== undefined">
                  <div class="align-items-center mt-4 mt-md-0">
                     <Chip v-if="articolo.programmaLink !== null && articolo.programmaLink[0].contenutoLink !== '/undefined'" :tagTitle="articolo.programmaLink" :isProgram="true" />
                     <Chip v-if="articolo.topic !== undefined" :tagTitle="articolo.topic" :isTag="true" />
                     <ProgettiStrategici v-if="(articolo.progettiStrategici !== undefined && articolo.progettiStrategiciOriginal !== undefined) && (articolo.progettiStrategici.length > 0 && articolo.progettiStrategiciOriginal.length > 0)" :progetto="articolo.progettiStrategici" :progettoFallback="articolo.progettiStrategiciOriginal" :isTag="true" :inCard="inList" />
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>
</template>
<script>
import Chip from '@/components/Chip';
import Markdown from 'vue3-markdown-it';
import ProgettiStrategici from '@/components/ProgettiStrategici';

export default {
   components: {
      Chip,
      Markdown,
      ProgettiStrategici
   },

   props: {
      type: {
         type: String,
         description: "Card type"
      },
      articolo: {
         type: Object,
         description: "Card object"
      },
      sezione: {
         type: String,
         description: "Card object"
      },
      showImage: {
         type: Boolean,
         default: true,
         description: "Mostra Immagine",
      },
      tipologia: {
         type: String,
         description: "Card object"
      },
      inList: {
         type: Boolean
      }
   },
   methods: {
      getNameLink(tipologia) {
         if (tipologia == 'notizia') {
            return "notizieArticolo";
         }
         else if (tipologia == 'evento') {
            return "eventiArticolo";
         }
         else if (tipologia == 'invito') {
            return "calendarioinvitiArticolo";
         }
         //else if(tipologia == 'programma'){
         //   return this.articolo.contenutoPadre[0].slug+"Articolo";
         //}
         else if (tipologia == 'priorita') {
            return this.namedLink(this.articolo.contenutoPadre[0].slug) + "Articolo";
         }
         else {
            if (this.articolo.contenutoPadre !== undefined && this.articolo.contenutoPadre[0].slug !== undefined && this.$props.tipologia == 'articolo') {
               return this.namedLink(this.articolo.contenutoPadre[0].slug) + "Articolo";
            }
            else {
               return ""
            }
         }
      },

      namedLink(str) {
         return str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Za-z0-9]/g, '').toLowerCase();
      }
   },

   computed: {
      link() {
         if (this.articolo.contenutoPadre[0].contenutoLink !== undefined && this.articolo.contenutoPadre[0].contenutoLink !== '/undefined') {
            let schema = '';

            if (this.articolo.schemaName === 'area-tematica' || this.articolo.schemaName === 'priorita-trasversale' || this.articolo.schemaName === 'dati-monitoraggio') {
               schema = this.articolo.schemaName + '/';
            }


            return this.articolo.contenutoPadre[0].contenutoLink + '/' + schema + this.$props.articolo.slug;
         }
         else {
            return '/' + this.$props.articolo.slug;
         }
      },

      linkID() {
         let linkID = this.$props.articolo.id;
         return linkID;
      },

      datePub() {
         if (this.$props.articolo.schemaName == 'evento') {
            return this.$filters.getDate(this.$props.articolo.calendarioEventi[0].dataDal);
         }
         else if (this.articolo.schemaName == 'ufficio') {
            return this.$props.articolo.dataCreated
         }
         else {
            if (this.$props.articolo.dataPubblicazione == 'NaN undefined NaN') {
               return null
            }
            else {
               return this.$props.articolo.dataPubblicazione
            }
         }
      },

      imageSrc() {
         if (this.$props.articolo.image.src.indexOf('unsplash') > -1) {
            return this.$props.articolo.image.src + '&w=540&h=414&q=75&fit=crop'
         } else {
            return this.$props.articolo.image.src;
         }
      },

      tags() {
         return Array.isArray(this.$props.articolo.programmaLink) ? this.$props.articolo.programmaLink[0] : this.$props.articolo.programma;
      },

      chipUrl() {
         if (this.$props.articolo.priorita[0].contenutoLink !== '') {
            return '/programmi/' + this.$props.articolo.priorita[0].contenutoLink + '/' + this.$props.articolo.priorita[0].schemaName + '/' + this.$props.articolo.priorita[0].slug;
         }
         else {
            return ""
         }
      }

      //         linkPath(){
      //            let ArticleSezione = this.$props.sezione == 'articolo' ? '/articolo' : '';
      //            return this.$route.fullPath +ArticleSezione+'/'+this.$props.articolo.slug;
      //         }
   }
};
</script>
<style scoped>
.card .card-body .card-text {
   min-height: unset !important;
}
</style>