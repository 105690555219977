<template>
   <div class="col-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after" :class="[
            { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
            { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
            { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
            { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
         ]">
            <template v-if="this.articolo.linkList !== undefined && this.articolo.linkList !== null && this.articolo.linkList.length > 0 && this.articolo.linkList[0].linkURL.indexOf('http') > -1">
               <a :href="link" target="_blank">
                  <div class="img-responsive-wrapper" v-if="articolo.image && showImage">
                     <div>
                        <figure>
                           <img :src="imageSrc" :alt="articolo.image.didascalia" class="card-img" style="min-height:280px; min-width:360px; object-fit:cover;" />
                           <figcaption>{{ articolo.titolo }}</figcaption>
                        </figure>
                     </div>
                  </div>
                  <div class="position-relative">
                     <div class="figcaption">{{ articolo.titolo }}</div>
                  </div>
               </a>
            </template>
            <template v-else>
               <router-link :to="{
            path: link,
            query: query,
            params: {
               nome: articolo.titolo,
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: articolo.tipoContenuto
            },
            props: {
               nome: articolo.titolo,
               id: articolo.id,
               slug: articolo.slug,
               tipo: articolo.schemaName,
               tipoContenuto: articolo.tipoContenuto
            }
         }">

                  <div class="img-responsive-wrapper" v-if="articolo.image && showImage">
                     <div>
                        <figure>
                           <img :src="articolo.image.src" :alt="articolo.image.didascalia" class="card-img" style="min-height:280px; min-width:360px; object-fit:cover;" />
                           <figcaption>{{ articolo.titolo }}</figcaption>
                        </figure>
                     </div>
                  </div>
                  <div v-if="!articolo.image">
                     <div class="figcaption">{{ articolo.titolo }}</div>
                  </div>
               </router-link>
            </template>
         </div>
      </div>
   </div>
</template>
<script>
//import shareArea from "@/components/ShareArea";

export default {
   components: {
   },

   props: {
      type: {
         type: String,
         description: "Card type"
      },
      sezione: {
         type: String,
         description: "Sezione",
         default: ''
      },
      showImage: {
         type: Boolean,
         default: true,
         description: "Mostra Immagine",
      },
      tipologia: {
         type: String,
         description: "Tipologia del contenuto",
         default: ''
      },
      articolo: {
         type: Object,
         description: "Card object"
      },
      inList: {
         type: Boolean
      }
   },
   computed: {
      nameLink() {
         let nameLink = this.$props.articolo.titolo;
         if (this.$props.articolo.schema == 'blocco') {
            nameLink = this.$route.name;
         }
         else {
            nameLink = this.$route.name + 'Articolo';
         }

         return nameLink;
      },

      link() {
         let preLink = this.$props.sezione !== '' ? this.$props.sezione + '/' : '';
         let tipoArt = this.$props.tipologia !== '' ? this.$props.tipologia + '/' : '';

         if (this.articolo.linkList !== undefined && this.articolo.linkList !== null && this.$props.articolo.linkList.length > 0) {
            return this.$props.articolo.linkList[0].linkURL;
         }
         else {
            return preLink + tipoArt + this.$props.articolo.slug
         }
      },

      query() {
         if (this.articolo.linkList !== undefined && this.articolo.linkList !== null && this.$props.articolo.linkList[0].linkURL.indexOf('?') > -1) {
            const searchParams = new URLSearchParams(this.$props.articolo.linkList[0].linkURL.substring(this.$props.articolo.linkList[0].linkURL.indexOf('?')));
            let params = {}
            for (const [key, value] of searchParams.entries()) {
               params[key] = value;
            }

            return params
         }
         else {
            return null;
         }
      },

      linkID() {
         let linkID = this.$props.articolo.id;
         return linkID;
      },

      imageSrc() {
         if (this.$props.articolo.image.src.indexOf('unsplash') > -1) {
            return this.$props.articolo.image.src + '&w=540&h=414&q=75&fit=crop'
         } else {
            return this.$props.articolo.image.src;
         }
      }
   },
   watch: {
   }
};
</script>
<style scoped></style>
