<template>
   <div class="col-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after" :class="[
            { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
            { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
            { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
            { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
         ]">
            <router-link :to="{
            path: link,
            props: {
               slug: articolo.slug,
               nome: articolo.titolo,
               id: articolo.id,
               tipo: articolo.schema,
               tipoContenuto: articolo.tipoContenuto
            }
         }">
               <div class="img-responsive-wrapper" v-if="articolo.image && showImage">
                  <div>
                     <figure>
                        <img :src="imageSrc" :alt="articolo.image.didascalia" class="card-img" style="min-height:280px; min-width:360px; object-fit:cover;" />
                        <figcaption>{{ articolo.titolo }}</figcaption>
                     </figure>
                  </div>
               </div>
               <div class=" flex-column d-flex position-relative" v-else>
                  <h5 class="figcaption">{{ articolo.titolo }}</h5>
               </div>
            </router-link>
         </div>
      </div>
   </div>
</template>
<script>
export default {
   components: {
      //      shareArea,
   },

   props: {
      type: {
         type: String,
         description: "Card type"
      },
      sezione: {
         type: String,
         description: "Sezione",
         default: ''
      },
      showImage: {
         type: Boolean,
         default: true,
         description: "Mostra Immagine",
      },
      articolo: {
         type: Object,
         description: "Card object"
      },
      inList: {
         type: Boolean
      }
   },

   computed: {
      nameLink() {
         let nameLink = this.$props.articolo.schema;

         if (this.$props.articolo.schema == 'priorita') {
            nameLink = this.$route.name + 'Articolo';
         }
         return nameLink;
      },
      link() {
         let preLink = this.$props.sezione !== '' ? this.$props.sezione + '/' : '';
         let schema = !this.$props.articolo.hasSottoSezione ? this.$props.articolo.schema + '/' : ''
         return preLink + schema + this.$props.articolo.slug
      },
      linkID() {
         let linkID = this.$props.articolo.id;
         return linkID;
      },

      imageSrc() {
         if (this.$props.articolo.image.src.indexOf('unsplash') > -1) {
            return this.$props.articolo.image.src + '&w=540&h=414&q=75&fit=clamp'
         } else {
            return this.$props.articolo.image.src;
         }
      }
   },

   mounted() {
   },

   watch: {
   }
};
</script>
<style scoped></style>
