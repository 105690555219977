<template>
<div class="col-12 col-md-12 col-lg-6 col-xl-4">
   <div class="card-wrapper card-space">
 
      <div class="card card-contatto card-flex card-bg no-after card-with-no-image bg-100">
      <router-link :to="{
         path: pathToContact,
      }" >
         <div class="card-body flex-column d-flex">
            <div class="card-text">
              <div class="d-flex my-2">
                <div class="pr-2">
                   <svg class="icon icon-primary icon-sm" aria-hidden="true">
                      <use href="@/assets/img/sprite.svg#it-user"></use>
                   </svg>
                </div>
                <div>
                 {{ articolo.titolo }} {{ articolo.cognome }} {{ articolo.nome }}                  
                </div>
              </div>
              <template v-for="(item, index) in articolo.telefonoList" :key="index">
                <div class="d-flex my-2">
                  <div class="pr-2">
                     <svg class="icon icon-primary icon-sm" aria-hidden="true">
                        <use href="@/assets/img/sprite.svg#it-telephone"></use>
                     </svg>
                    </div>
                    <div>
                    <b v-show="item.telefonoDesc !== ''">{{ item.telefonoDesc }}</b> {{ item.telefono }}
                  </div>
                </div>
              </template>              
              <template v-for="(item, index) in articolo.emailList" :key="index">
                <div class="d-flex my-2">
                  <div class="pr-2">
                     <svg class="icon icon-primary icon-sm" aria-hidden="true">
                        <use href="@/assets/img/sprite.svg#it-mail"></use>
                     </svg>
                    </div>
                    <div>
                    <b v-show="item.emailDesc !== ''">{{ item.emailDesc }}</b> {{ item.email }}
                  </div>
                </div>
              </template>
              <template v-for="(item, index) in articolo.localizzazione" :key="index">
                <div class="d-flex my-2">
                  <div class="pr-2">
                     <svg class="icon icon-primary icon-sm" aria-hidden="true">
                        <use href="@/assets/img/sprite.svg#it-pin"></use>
                     </svg>
                    </div>
                    <div>
                    {{ item.indirizzoTesto }}
                    <div><a :href="item.indirizzoURL" target="_blank" class="text-normal">{{item.indirizzoURL}}</a></div>
                  </div>
                </div>
              </template>    
              <div class="d-flex my-2" v-if="articolo.strutturaOrganizzativa !== null">
                <div class="pr-2">
                   <svg class="icon icon-primary icon-sm" aria-hidden="true">
                      <use href="@/assets/img/sprite.svg#it-pa"></use>
                   </svg>
                  </div>
                  <div>
                  {{ articolo.strutturaOrganizzativa }}
                </div>
              </div>
            </div>
         </div>
             </router-link>

      </div>
   </div>
</div>
</template>
<script>
   export default {
      components: {
      },

      props: {
         type: {
           type: String,
           description: "Card type"
         },
         articolo: {
           type: Object,
           description: "Card object"
         },
         sezione: {
           type: String,
           description: "Card object"
         },
         inList:{
            type: Boolean
         }               
      },
      computed: {
        pathToContact(){
          return '/rubrica/contatto-rubrica/'+ this.articolo.slug
        }
      }
   };
</script>
<style scoped>
  .card-contatto a:hover{ text-decoration:none }
</style>