<template>
   <div class="col-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after">
            <div class="card card-bg card-with-image no-after">
               <figure>
                  <img class="card-img" :src="'https://source.unsplash.com/featured/640x640'" :alt="'test'"/>
                  <figcaption>Caption</figcaption>
               </figure>  
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   export default {
      components: {
      },

      data() {
         return {
         }         
      },  

      props: {   
         id: {
            type: String,
            description: "Image"
         },
         articolo: {
            type: Object,
            description: "Article"
         },   
      },
   };
</script>

