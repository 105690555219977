<template>
   <div class="col-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card-wrapper card-space">
         <div class="card card-flex card-bg no-after" :class="[
            { ['card-with-image']: articolo.image !== null && articolo.image !== undefined && showImage },
            { ['card-with-video']: articolo.video !== null && articolo.video !== undefined && showImage },
            { ['card-with-video-embed']: articolo.video !== null && articolo.video !== undefined && articolo.video.videoURL.indexOf('you') > 1 && showImage },
            { ['card-with-no-image']: ((articolo.image == null || articolo.image == undefined) && (articolo.video == null || articolo.video == undefined)) || !showImage },
         ]">
            <div class="img-responsive-wrapper">
               <div class="img-responsive">
                  <figure class="img-wrapper">
                     <img :src="'https://source.unsplash.com/featured/640x640'" :alt="'Didascalia'">
                  </figure>
               </div>
            </div>
            <div class="card-body flex-column d-flex">
               <span class="date-detail">{{ articolo.dataPubblicazione }}</span>
               <div class="category-top text-uppercase my-1">
                  <b>
                     ARGOMENTO
                  </b>
               </div>
               <h5 class="card-title">
                  {{ articolo.titolo }}
               </h5>

               <p class="card-text">
                  <Markdown :source="articolo.breve" />
               </p>

               <div class="head-tags mt-auto mb-2" v-if="articolo.topic !== undefined">
                  <div class="align-items-center  mt-4 mt-md-0">
                     <Chip v-if="articolo.topic !== undefined" :tagTitle="articolo.topic" :isTag="true"></Chip>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import Chip from '@/components/Chip';
import Markdown from 'vue3-markdown-it';

export default {
   components: {
      Chip,
      Markdown
   },

   props: {
      type: {
         type: String,
         description: "Card type"
      },
      articolo: {
         type: Object,
         description: "Card object"
      },
      showImage: {
         type: Boolean,
         default: true
      },
      inList: {
         type: Boolean
      }
   }
};
</script>